import React from 'react';
import { List, Segment } from 'semantic-ui-react';
import { FormattedMessage, injectIntl } from 'react-intl';


const Footer = ({ intl }) => {
    const currentYear = new Date().getFullYear();

    return (
        <Segment role="contentinfo" padded id="footer">
            <div className="footer-curve"></div>
            <div className="footerTop">
                <div>
                    <List className="footerList">
                        <div role="listitem" className="item footerName">
                            <p className="item">
                                <FormattedMessage
                                    id='Footer-Oeffnungszeiten'
                                    defaultMessage='Öffnungszeiten'
                                />
                            </p>
                        </div>
                        <div role="listitem" className="item">
                            <p className="item">
                                <FormattedMessage
                                    id='Footer-MO'
                                    defaultMessage='Montag - Ruhetag'
                                />
                            </p>
                        </div>
                        <div role="listitem" className="item">
                            <p className="item">
                                <FormattedMessage
                                    id='Footer-DI-FR'
                                    defaultMessage="Dienstag - Freitag"
                                />
                            </p>
                        </div>
                        <div role="listitem" className="item">
                            <p className="item">
                                <FormattedMessage
                                    id='Footer-DI-FR-Zeit'
                                    defaultMessage="06:00 - 19:00"
                                />
                            </p>
                        </div>
                        <div role="listitem" className="item">
                            <p className="item">
                                <FormattedMessage
                                    id='Footer-SA-SO'
                                    defaultMessage="Samstag & Sonntag"
                                />
                            </p>
                        </div>
                        <div role="listitem" className="item">
                            <p className="item">
                                <FormattedMessage
                                    id='Footer-SA-SO-Zeit'
                                    defaultMessage="06:30 - 18:00"
                                />
                            </p>
                        </div>
                    </List>
                </div>

                <div>
                    <List className="footerList">
                        <div role="listitem" className="item footerName">
                            <p className="item">
                                <FormattedMessage
                                    id='Footer-Kontakt'
                                    defaultMessage='Kontakt'
                                />
                            </p>
                        </div>
                        <div role="listitem" className="item">
                            <i className="ri-mail-line"></i>
                            <a className="item" href="mailto:baeckerei@liechti-beck.ch">
                                <FormattedMessage
                                    id='Footer-Email'
                                    defaultMessage="baeckerei@liechti-beck.ch"
                                />
                            </a>
                        </div>
                        <div role="listitem" className="item">
                            <i className="ri-phone-line"></i>
                            <a className="item" href="tel:034 496 81 97">
                                <FormattedMessage
                                    id='Footer-Telefon'
                                    defaultMessage="034 496 81 97"
                                />
                            </a>
                        </div>
                        <div role="listitem" className="item">
                            <i className="ri-printer-line"></i>
                            <a className="item" href="fax:034 496 74 06">
                                <FormattedMessage
                                    id='Footer-Fax'
                                    defaultMessage="034 496 74 06 (fax)"
                                />
                            </a>
                        </div>
                    </List>
                </div>

                <div>
                    <List className="footerList">
                        <div role="listitem" className="item footerName">
                            <p className="item">
                                <FormattedMessage
                                    id="Footer-Name"
                                    defaultMessage="Bäckerei-Konditorei-Cafe-Restaurant Liechti"
                                />
                            </p>
                        </div>
                        <div role="listitem" className="item">
                            <p className="item">
                                <FormattedMessage
                                    id="Footer-Adress"
                                    defaultMessage="Bahnhofstrasse 4"
                                />
                            </p>
                        </div>
                        <div role="listitem" className="item">
                            <p className="item">
                                <FormattedMessage
                                    id='Footer-Plz-Ort'
                                    defaultMessage="3436 Zollbrück"
                                />
                            </p>
                        </div>
                        <br />
                        <div role="listitem" className="item">
                            <a className="item" href="https://www.facebook.com/liechtibeck" target='_blank'>
                                <i className='ri-facebook-circle-fill' style={{ fontSize: "25pt" }}></i>
                            </a>
                        </div>
                    </List>
                </div>

            </div>

            <div className='footerBottom'>
                <div>
                    <List className="footerList">
                        <div role="listitem" className="item">
                            <a className="item" href="./impressum">
                                <FormattedMessage
                                    id='Footer-Imprint'
                                    defaultMessage="Impressum"
                                />
                            </a>
                        </div>
                        <div role="listitem" className="item">
                            <a className="item" href="./datenschutz">
                                <FormattedMessage
                                    id='Footer-Privacy'
                                    defaultMessage="Datenschutzerklärung"
                                />
                            </a>
                        </div>
                        <div role="listitem" className="item">
                            <i className="ri-copyright-line"></i>
                            {currentYear}&nbsp;
                            <FormattedMessage
                                id='Footer-Copyright'
                                defaultMessage="Liechti-Beck GmbH"
                            />
                        </div>
                    </List>
                </div>
            </div>
        </Segment>
    );
};

export default injectIntl(Footer);