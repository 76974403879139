import $ from 'jquery';

//Check if element is in Viewport
$.fn.isInViewport = function () {
  var elementTop = $(this).offset().top;
  var elementBottom = elementTop + $(this).outerHeight();

  var viewportTop = $(window).scrollTop();
  var viewportBottom = viewportTop + $(window).height();

  return elementBottom > viewportTop && elementTop < viewportBottom;
};

//Set header size for spesific screen width
$(function () {
  if (screen.width > 1070) {
    $(".header-wrapper").data("size", "big");
  }
});

$(window).on("load", function () {
  $(".dropdownmenu-item").each(function (elem) {
    var pos = $(this).position().left;
    $(this).next(".dropdown-menu-wrapper").css("left", pos);
  })

  if (screen.width <= 990) {
    $(".ui.pointing.secondary.stackable.menu").children().wrapAll('<div class="mobileNavWrapper"></div>')
  }

  // Slide in Title in Hero block
  $(".animateTitle h2").animate({
    bottom: "0px",
    opacity: 1,
  }, 2000);
})

$(window).on("resize", function () {
  if (screen.width > 1070) {
    $(".public-ui .header-wrapper").data("size", "big");
  }

  $(".dropdownmenu-item").each(function (elem) {
    var pos = $(this).position().left;
    $(this).next(".dropdown-menu-wrapper").css("left", pos);
  })
});

$(window).on("scroll", (function () {

  //Check for animateFadeIn blocks if they are in viewport and animate them
  $(".animateFadeIn").each(function() {
    if (!$(this).attr("data-isAnimated") && $(this).isInViewport()) {
      $(this).attr("data-isAnimated", "true");
      animateFadeInBlocks($(this));
    };  
  })

  //Check for animateMoveIn blocks if they are in viewport and animate them
  $(".animateMoveIn").each(function() {
    if (!$(this).attr("data-isAnimated") && $(this).isInViewport()) {
      $(this).attr("data-isAnimated", "true");
      animateMoveInBlocks($(this));
    };  
  })

  if (screen.width > 1070) {
    var imgHeight = $(":root").css("--imgHeight");
    var headerTop = $(":root").css("--headerTop");

    if ($(document).scrollTop() > 0) {
      if ($(".public-ui .header-wrapper").data("size") == "big") {
        $(".public-ui .header-wrapper").data("size", "small");
        $(".public-ui .header-wrapper").stop().animate({
          height: "5em",
          top: "0",
          "padding-top": "0",
          "padding-bottom": "0",
        }, 600);
        $(".public-ui .header-wrapper > .ui.container > .header > .logo-nav-wrapper > .logo > a > img").stop().animate({
          height: "5em",
        }, 600);
        $(".public-ui .bgImg").stop().animate({
          height: "0",
        }, 800);
        $(".public-ui #page-document").stop().animate({
          "padding-top": "0em",
        }, 600);
      }
    }
    else {
      if ($(".public-ui .header-wrapper").data("size") == "small") {
        $(".public-ui .header-wrapper").data("size", "big");
        $(".public-ui .bgImg").stop().animate({
          height: imgHeight,
        }, 600);
        $(".public-ui .header-wrapper").stop().animate({
          height: "8.5em",
          top: headerTop,
          "padding-top": "14px",
          "padding-bottom": "14px",
        }, 600);
        $(".public-ui .header-wrapper > .ui.container > .header > .logo-nav-wrapper > .logo > a > img").stop().animate({
          height: "6.5em",
        }, 600);
        $(".public-ui #page-document").stop().animate({
          "padding-top": "0",
        }, 600);
      }
    }
  }
}));

//Animate fade in of blocks if they are in viewport
function animateFadeInBlocks(block) {
  $(block).fadeIn( 3000, function() {});
}

//Animate move in from left and right of blocks if they are in viewport
function animateMoveInBlocks(block) {
  $(block).find(".column-blocks-wrapper").even().addClass('moveInLeft');
  $(block).find(".column-blocks-wrapper").odd().addClass('moveInRight');
}